import { NotFoundPageComponent } from 'c4p-portal-util';

import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  DashboardRouterGuardService
} from '../../../../c4p-portal-util/src/lib/services/dashboard-router-guard/dashboard-router-guard.service';
import { AppComponent } from '../app.component';


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [DashboardRouterGuardService],
    component: AppComponent,
  },
  {
    path: 'dashboard',
    data: {
      breadcrumbLabel: '',
      verticalIndex: 4,
    },
    loadChildren: () =>
      import('../dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'system-administration',
    data: {
      verticalIndex: 0,
    },
    loadChildren: () =>
      import('../system-administration/system-administration.module').then(
        (m) => m.SystemAdministrationModule,
      ),
  },
  {
    path: 'employees',
    data: {
      verticalIndex: 1,
    },
    loadChildren: () =>
      import('../employee/employee.module').then((m) => m.EmployeeModule),
  },
  {
    path: 'formats',
    data: {
      verticalIndex: 2,
    },
    loadChildren: () =>
      import('../questionset/questionset.module').then(
        (m) => m.QuestionSetModule,
      ),
  },
  {
    path: 'checklist',
    data: {
      verticalIndex: 3,
    },
    loadChildren: () =>
      import('../checklist/checklist.module').then((m) => m.ChecklistModule),
  },
  {
    path: 'clients',
    data: {
      verticalIndex: 4,
    },
    loadChildren: () =>
      import('../client/client.module').then((m) => m.ClientModule),
  },
  {
    path: 'applications',
    data: {
      verticalIndex: 5,
    },
    loadChildren: () =>
      import('../application/application.module').then(
        (m) => m.ApplicationModule,
      ),
  },
  {
    path: 'teams',
    data: {
      verticalIndex: 6,
    },
    loadChildren: () => import('../team/team.module').then((m) => m.TeamModule),
  },
  {
    path: 'carefiles',
    data: {
      verticalIndex: 7,
    },
    loadChildren: () =>
      import('../carefile/carefile.module').then((m) => m.CarefileModule),
  },
  {
    path: 'product-catalog',
    data: {
      verticalIndex: 8,
    },
    loadChildren: () =>
      import('../product-catalog/product-catalog.module').then(
        (m) => m.ProductCatalogModule,
      ),
  },
  {
    path: 'logbook',
    data: {
      verticalIndex: 9,
    },
    loadChildren: () =>
      import('../logbook/logbook.module').then((m) => m.LogbookModule),
  },
  {
    path: 'cost-price-components',
    data: {
      verticalIndex: 9,
    },
    loadChildren: () =>
      import('../cost-price-component/cost-price-component.module').then(
        (m) => m.CostPriceComponentModule,
      ),
  },
  {
    path: 'hours',
    data: {
      verticalIndex: 10,
    },
    loadChildren: () =>
      import('../hours/hours.module').then((m) => m.HoursModule),
  },
  {
    path: 'docgen',
    loadChildren: () =>
      import('../docgen/docgen.module').then((m) => m.DocgenModule),
  },
  {
    path: 'e-health',
    data: {
      verticalIndex: 10,
    },
    loadChildren: () =>
      import('../e-health/e-health.module').then((m) => m.EHealthModule),
  },
  {
    path: 'socialarea',
    data: {
      verticalIndex: 11,
    },
    loadChildren: () =>
      import('../social-area/socialarea.module').then(
        (m) => m.SocialAreaModule,
      ),
  },
  {
    path: 'topics',
    data: {
      verticalIndex: 12,
    },
    loadChildren: () =>
      import('../topic/topic.module').then((m) => m.TopicModule),
  },
  {
    path: 'billing',
    data: {
      // TODO: change all the indexes 😬
      verticalIndex: 13,
    },
    loadChildren: () =>
      import('../billing/billing.module').then((m) => m.BillingModule),
  },
  {
    path: 'availability',
    data: {
      verticalIndex: 14,
    },
    loadChildren: () =>
      import('../availability/availability.module').then(
        (m) => m.AvailabilityModule,
      ),
  },
  {
    path: 'participations',
    data: {
      verticalIndex: 15,
    },
    loadChildren: () =>
      import('../participation/participation.module').then(
        (m) => m.ParticipationModule,
      ),
  },
  {
    path: 'care-program',
    data: {
      verticalIndex: 16,
    },
    loadChildren: () =>
      import(
        '../administration-careprogram/administration-careprogram.module'
      ).then((m) => m.AdministrationCareprogramModule),
  },
  {
    path: 'group-consults',
    data: {
      verticalIndex: 17,
    },
    loadChildren: () =>
      import('../group-consults/group-consults.module').then(
        (m) => m.GroupConsultsModule,
      ),
  },
  {
    path: 'assessments',
    data: {
      verticalIndex: 18,
    },
    loadChildren: () =>
      import(
        '../administration-assessments/administration-assessments.module'
      ).then((m) => m.AdministrationAssessmentModule),
  },
  {
    path: 'user-settings',
    loadChildren: () =>
      import(
        '../../../../c4p-portal-util/src/lib/user-settings/user-settings.module'
      ).then((m) => m.UserSettingsModule),
  },
  {
    path: 'search/:data',
    loadChildren: () =>
      import('../search/search.module').then((m) => m.SearchModule),
  },
  { path: '', children: [] },
  { path: 'not-found', component: NotFoundPageComponent },
  { path: '**', redirectTo: 'not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [DashboardRouterGuardService],
  exports: [RouterModule],
})
export class AppRoutingModule {}



